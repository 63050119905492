import { QRCodeCanvas } from 'qrcode.react'
import SecondaryNavbar from '../SecondaryNavbar/secondary-navbar'

/**
 * @returns {import('react').FunctionComponentElement}
 */
export default function PageConnectMobile() {

   return (
      <div style={{ height: '100vh', overflowY: 'hidden', flexDirection: 'column', display: 'flex' }}>
         <SecondaryNavbar
            title="Connect mobile device"
         >
         </SecondaryNavbar>
         <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '12px', marginBottom: '80px' }}>
               <h2 style={{ marginBottom: '52px' }}>Connect mobile app by scanning QR Code or by typing the url</h2>
               <QRCodeCanvas value={window.location.origin} />
               <h4 style={{ marginTop: '52px', userSelect: 'text' }}>{window.location.origin}</h4>
            </div>
         </div>
      </div>
   )
}
